import * as Sentry from '@sentry/node';
import * as SentryIntegrations from '@sentry/integrations';
import getConfig from 'next/config';
import Cookie from 'js-cookie';

interface SentryInit {
  Sentry: any;
  captureException: (err: any, ctx: any) => string;
}

export const SentryInitialization = (
  release: string | undefined = process.env.SENTRY_RELEASE
): SentryInit => {
  let sdsn = process.env.SENTRY_DSN;
  if (typeof window !== 'undefined') {
    const {
      publicRuntimeConfig: { SENTRY_DSN },
    } = getConfig();
    sdsn = SENTRY_DSN;
  }

  const ops: any = {
    dsn: sdsn,
    release,
    maxBreadcrumbs: 20,
    attachStacktrace: true,
  };

  let integrations: any[] = [
    new SentryIntegrations.CaptureConsole({ levels: ['error'] }),
    new SentryIntegrations.Dedupe(),
  ];
  if (process.env.NODE_ENV !== 'production') {
    ops.beforeSend = () => null;
    integrations = integrations.concat([new SentryIntegrations.Debug({ debugger: false })]);
    if (typeof window === 'undefined') {
      integrations = integrations.concat([
        new SentryIntegrations.RewriteFrames({
          root: global.__rootdir__,
        }),
      ]);
    }
  }
  ops.integrations = integrations;

  Sentry.init(ops);

  Sentry.configureScope(scope => {
    if (typeof window !== 'undefined') {
      // On client-side we use js-cookie package to fetch it
      const sessionId = Cookie.get('sessionid');
      const email = Cookie.get('email');
      if (sessionId) {
        scope.setUser({ id: sessionId, email });
      }
      const lang = Cookie.get('next-i18next');
      if (lang) {
        scope.setExtra('lang', lang);
      }
    }
  });

  return {
    Sentry,
    captureException: (err: any, ctx: any) => {
      Sentry.configureScope(scope => {
        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', 'false');
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);
          } else {
            scope.setTag('ssr', 'true');
            scope.setExtra('url', req.url);
            scope.setExtra('method', req.method);
            scope.setExtra('headers', req.headers);
            scope.setExtra('params', req.params);
            scope.setExtra('query', req.query);

            // On server-side we take session cookie directly from request
            // console.log(req.cookies);
            if (req.cookies.sessionid) {
              scope.setUser({ id: req.cookies.sessionid });
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]));
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};

export default SentryInitialization;
